import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import AccordionItem from '~components/blocks/AccordionItem'
import RichText from '~components/RichText'
import Section from '~components/Section'
import Button from '~components/blocks/Button'
import resolveLink from '~utils/resolveLink'

const TextAccordionModule = ({ className, content }) => {

	const mobile = '@media (max-width: 768px)'

	return (
		<Section className={className}>
			<div css={css`
				grid-column: 2/5;
				${mobile}{
					grid-column: span 12;
				}
			`}>
				<h4 css={css`
					margin-bottom: 28px;
				`}>
					{content?.title}
				</h4>
				<div>
					<p css={css`
						padding-bottom: 38px;
						${mobile}{
							padding-bottom: 42px;
						}
					`}>
						{content?.subtitle}
					</p>
				</div>
				<div css={css`${mobile}{margin-bottom: 56px;}`}>
				<RichText content={content?.text}/>
				</div>
			</div>
			<div css={css`
				grid-column: 7/12;
				border-top: 1px solid var(--grey);
				margin-top: 8px;
				${mobile}{
					grid-column: span 12;
				}
			`}>
				{content?.accordion?.map(accordionItem => (
					<AccordionItem title={accordionItem?.title} key={accordionItem?._key}>
						<div css={css`
							max-width: 400px;
							margin-bottom: 27px;
						`}>
							<RichText content={accordionItem?.text} />
						</div>
						<Button to={resolveLink(accordionItem?.link?.document?._type, accordionItem?.link?.document?.slug?.current, accordionItem?.link?.document?.parent?.slug?.current)}>{accordionItem?.linkText}</Button>
					</AccordionItem>
				))}
			</div>
		</Section>
	)
}

const Wrap = styled.div`
	
`

TextAccordionModule.propTypes = {
	className: PropTypes.string
}

export default TextAccordionModule