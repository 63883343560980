import React from 'react'
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { breakpoints } from '~styles/global'
import Section from '~components/Section'
import Button from '~components/blocks/Button'
import ProductCard from '~components/blocks/ProductCard'
import * as Flickity from 'react-flickity-component'
import "~styles/flickity.css";
import { Larr, Rarr } from '../../Svg';
import resolveLink from '~utils/resolveLink'


class ProductGridSlider extends React.Component {

  render() {

    const myCustomPrev = () => {
      this.flkty.previous()
    }

    const myCustomNext = () => {
      this.flkty.next()
    }

    const flickityOptions = {
      initialIndex: 0,
      cellAlign: 'left',
      prevNextButtons: false,
      pageDots: false,
      wrapAround: true,
      lazyload: 5,
    }

    return (
      <>
        <Flickity
            flickityRef={c => this.flkty = c}
            className={'carousel'} // default ''
            elementType={'div'} // default 'div'
            options={flickityOptions} // takes flickity options {}
            disableImagesLoaded={false} // default false
            reloadOnUpdate // default false
            static // default false
            css={css`
              margin-bottom: 32px;
            `}
          >
            {this.props.products?.map(product =>(
              <ProductCard
                css={css`
                  width: 77%;
                  margin-right: 10px;
              `}
                product={product}
                productId={product._id}
                title={product?.title}
                slug={product?.slug?.current}
                image={product?.content?.cardImage?.asset?.id}
                lifestyleImage={product?.content?.cardLifestyleImage?.asset}
                cardDesc={product?.content?.cardDesc}
                brand={product?.content?.brand?.title}
                price={product?.content?.priceFrom}
                link={resolveLink(product?._type, product?.slug?.current)}
                key={product?._id}
              />
            ))}
        </Flickity>
        <button
          onClick={() => myCustomPrev()}
          css={css`
            margin-right: 56px;
            padding: 4px;
        `}>
          <Larr css={css`
            height: 11px;
            width: 11px;
          `}/>
        </button>
        <button
          onClick={() => myCustomNext()}
        >
          <Rarr css={css`
            height: 11px;
            width: 11px;
          `}/>
        </button>
      </>
    )
  }
}

const ProductGridModule = ({ className, products }) => {

  const {mobile, tablet} = breakpoints

  return (
    <Section
      className={className}
    >
      <div css={css`
        grid-column: span 12;
        display: grid;
        justify-items: center;
        grid-template-columns: repeat(3, 1fr);
        padding: 0 45px;
        grid-column-gap: 8%;
        ${tablet}{
          padding: 0 0px;
        }
        @media (max-width: 750px){
          display: none;
        }
      `}>
        {products?.map((product, index) => (
          <ProductCard
            css={css`
              width: 100%;
            `}
            title={product?.title}
            image={product?.content?.cardImage?.asset?.id}
            lifestyleImage={product?.content?.cardLifestyleImage?.asset?.id}
            cardDesc={product?.content?.cardDesc}
            slug={product?.slug?.current}
            brand={product?.content?.brand?.title}
            price={product?.content?.priceFrom}
            link={resolveLink(product?._type, product?.slug?.current)}
            key={product?._id}
            productId={product?._id}
          />
        ))}
      </div>
      <div css={css`
        grid-column: span 12;
        display: none;
        @media (max-width: 750px) {
          display: block;
          margin-right: -25px;
        }
      `}>
         <ProductGridSlider products={products}/>
      </div>
    </Section>
  )
}

ProductGridModule.propTypes = {
  products: PropTypes.array,
  storeLink: PropTypes.string,
}

export default ProductGridModule
