import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import Section from '~components/Section'
import Button from '~components/blocks/Button'
import ContentTile from '~components/blocks/ContentTile'
import ContentGridSlider from '~components/modules/ContentGridSlider'

const index = ({ className, content }) => {
	return (
		 <Section className={className} >
			{/* <Button css={css`
					grid-column: span 12;
					justify-self: start;
					margin-bottom: 108px;
					@media (max-width: 750px){
						margin-bottom: 66px;
					}
				`}
				to={categoriesLink}
			>
				{categoriesLinkHeading}
			</Button> */}
			<div css={css`
				grid-column: span 12;
				display: grid;
				justify-items: center;
				grid-template-columns: repeat(3, 1fr);
				grid-column-gap: 8%;
				@media (max-width: 750px){
					display: none;
				}
			`}>
				{content?.gridItems?.map((item) =>(
					<ContentTile
						content={item}
						key={item?._key}
					/>
				))}
			</div>
			<div css={css`
				grid-column: span 12;
				display: none;
				@media (max-width: 750px) {
					margin-right: -25px;
					display: block;
				}
			`}>
			 	<ContentGridSlider slides={content?.gridItems}/>
			</div>
		</Section>
	)
}

const Wrap = styled.div`
	
`

index.propTypes = {
	className: PropTypes.string
}

export default index