import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import ModularPageHeader from '~components/blocks/ModularPageHeader'
import { graphql } from 'gatsby'
import RenderModules from '~components/RenderModules'

const ModularPage = ({ data }) => {

  const page = data?.sanityModularPage

	console.log(page)

	return (
		<Wrap>
			{(page?.text || page?.image) &&<ModularPageHeader content={page}/>}
			<RenderModules modules={page?.modules}/>
		</Wrap>
	)
}

export const query = graphql`
  query modularPageQuery($slug: String){
    sanityModularPage(slug: {current: {eq: $slug}}) {
      id
      title
      slug {
        current
      }
			image {
				asset {
					id
				}
    	}
			text: _rawText(resolveReferences: {maxDepth: 6})
			modules {
				... on SanityContentGridModule {
					_key
					_type
					gridItems {
						_key
						image {
							asset {
								id
							}
						}
						_key
						text
						title
						link {
							linkType
							url
							document: _rawDocument(resolveReferences: {maxDepth: 4})
						}
						buttonText
					}
				}
				... on SanityImageAndTextModule {
					_key
					_type
					title
					subtitle
					image {
						asset {
							id
						}
					}
					layout
					text: _rawText(resolveReferences: {maxDepth: 6})
					verticalAlign
				}
				... on SanityImageModule {
					_key
					_type
					image {
						asset {
							id
						}
					}
					caption: _rawCaption(resolveReferences: {maxDepth: 6})
				}
				... on SanityProductGridModule {
					_key
					_type
					products {
						title
						slug: slug {
							current
						}
						_type
						content {
							cardImage {
								asset {
									id
								}
							}
						}
					}
				}
				... on SanityTextAccordionModule {
					_key
					_type
					accordion {
						title
						text: _rawText(resolveReferences: {maxDepth: 6})
						link {
							linkType
							url
							document: _rawDocument(resolveReferences: {maxDepth: 2})
						}
						linkText
					}
					subtitle
					text: _rawText(resolveReferences: {maxDepth: 6})
					title
				}
				... on SanityTextModule {
					_key
					_type
					title
					subtitle
					textContent: _rawText(resolveReferences: {maxDepth: 6})
				}
				... on SanitySliderModule {
					_key
					_type
					slider{
						mediaType
						image: _rawImage(resolveReferences: {maxDepth: 2})
						mobileImage: _rawMobileImage(resolveReferences: {maxDepth: 2})
						video{
							asset {
								url
							}
						}
						videoMobile{
							asset {
								url
							}
						}
						link {
							linkType
							url
							document: _rawDocument(resolveReferences: {maxDepth: 2})
						}
						linkText
						queryString
						title
						text: _rawText(resolveReferences: {maxDepth: 6})
					}
				}
			}
    }
  }
`


const Wrap = styled.div`
	
`

ModularPage.propTypes = {
	className: PropTypes.string
}

export default ModularPage