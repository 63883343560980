import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
// import { mobile, tablet } from '~styles/global'
import ContentGridModule from '~components/modules/ContentGridModule'
import ImageAndTextModule from '~components/modules/ImageAndTextModule'
import ImageModule from '~components/modules/ImageModule'
import TextModule from '~components/modules/TextModule'
import TextAccordionModule from '~components/modules/TextAccordionModule'
import ProductGridModule from '~components/modules/ProductGridModule'
import SliderModule from '~components/modules/SliderModule'

const RenderModules = ({ modules }) => {

	return (
		modules?.map(module => (
			<RenderModule  key={module._key} module={module}/>
		))
	)
}

const RenderModule = ({ module, className }) => {
	switch (module._type) {
	case 'contentGridModule':
		return (
			<ContentGridModule 
				content={module}
				css={modulePadding}
			/>
		)
	case 'imageAndTextModule':
		return (
			<ImageAndTextModule 
				content={module}
				css={modulePadding}
			/>
		)
	case 'imageModule':
		return (
			<ImageModule 
				image={module?.image?.asset?.id}
				caption={module?.caption}
				css={modulePadding}
			/>
		)
	case 'textModule':
		return (
			<TextModule 
				text={module?.textContent}
				title={module?.title}
				subtitle={module?.subtitle}
				css={modulePadding}
			/>
		)	
	case 'textAccordionModule':
		return (
			<TextAccordionModule 
				content={module}
				css={modulePadding}
			/>
		)
	case 'productGridModule':
		return (
			<ProductGridModule 
				products={module?.products}
				css={modulePadding}
			/>
		)
	case 'sliderModule':
		return (
			<>
				<SliderModule 
					slides={module?.slider}
					css={modulePadding}
				/>
			</>
		)
	default:
		return null
	}
}

const mobile = '@media (max-width: 768px)'

const modulePadding = css`
	margin: 250px 0;
	&:first-of-type{
		margin-top: 0;
	}
	&:last-of-type{
		margin-bottom: 0;
	}
	${mobile}{
		margin: 150px 0;
		&:first-of-type{
			margin-top: 0;
		}
		&:last-of-type{
			margin-bottom: 0;
		}
	}
`

const Wrap = styled.div`
`

RenderModules.propTypes = {
	className: PropTypes.string
}

export default RenderModules