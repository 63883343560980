import React from 'react'
import PropTypes from 'prop-types';
import SliderBlock from '~components/blocks/SliderBlock'

const SliderModule = ({ className, slides  }) => {

  return (
    <SliderBlock slides={slides} className={className}>
    </SliderBlock>
  )
}

SliderModule.propTypes = {
  slides: PropTypes.array,
  className: PropTypes.string,
  headline: PropTypes.string,
}

SliderModule.defaultProps = {
}

export default SliderModule
