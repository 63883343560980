import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import * as Flickity from 'react-flickity-component'
import "~styles/flickity.css";
import { Larr, Rarr } from '../../Svg';
import ContentTile from '~components/blocks/ContentTile'

class CategoryGridSlider extends React.Component {

  render() {

    const myCustomPrev = () => {
      this.flkty.previous()
    }

    const myCustomNext = () => {
      this.flkty.next()
    }

    const flickityOptions = {
      initialIndex: 0,
      cellAlign: 'left',
      prevNextButtons: false,
      pageDots: false,
      wrapAround: true,
      lazyLoad: 5,
    }

    return (
      <>
        <Flickity
            flickityRef={c => this.flkty = c}
            className={'carousel'} // default ''
            elementType={'div'} // default 'div'
            options={flickityOptions} // takes flickity options {}
            disableImagesLoaded={false} // default false
            reloadOnUpdate // default false
            static // default false
            css={css`
              margin-bottom: 12px;
            `}
          >
            {this.props.slides?.map(slide =>(
              <ContentTile
                css={css`
                  width: 53%;
                  margin-right: 10px;
                `}
								content={slide}
                key={slide._key}
              />
            ))}
        </Flickity>
        {/* <button 
          onClick={() => myCustomPrev()}
          css={css`
            margin-right: 56px;
            padding: 4px;
        `}>
          <Larr css={css`
            height: 11px;
            width: 11px;
          `}/>
        </button>
        <button 
          onClick={() => myCustomNext()}
        >          
          <Rarr css={css`
            height: 11px;
            width: 11px;
          `}/>
        </button> */}
      </>
    )
  }
}

const Wrap = styled.div`
	
`

CategoryGridSlider.propTypes = {
	className: PropTypes.string
}

export default CategoryGridSlider