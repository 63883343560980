import React from 'react'
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { breakpoints } from '~styles/global'
import RichText from '~components/RichText'

const TextModule = ({ className, text, title, subtitle }) => {

  const {mobile} = breakpoints

  return (
    <div
      className={className}
      css={css`
        display: grid;
        grid-template-columns: repeat(10, 1fr);
        column-gap: 20px;
				padding: 0 40px;
        ${mobile} {
          grid-template-columns: repeat(12, 1fr);
          column-gap: 10px;
					padding: 0 25px;
        }
    `}>
      <div css={css`
        grid-column: 3/9;
				text-align: center;
        ${mobile}{
          grid-column: span 12;
        }
      `}>
				{title && 
					<h4 
						css={css`
							margin-bottom: 34px;
							text-transform: uppercase;
							${mobile}{
								margin-bottom: 20px;
							}
						`}
					>
						{title}
					</h4>
				}
				{subtitle && 
					<div 
						css={css`
							margin-bottom: 34px;
							${mobile}{
								margin-bottom: 20px;
							}
						`}
					>
						{subtitle}
					</div>
				}
        <div 
				css={css`
					ul, ol {
						list-style-position: inside;
					}
				`}>
					<RichText content={text}/>
				</div>
      </div>
    </div>
  )
}

TextModule.propTypes = {
  text: PropTypes.array,
}

TextModule.defaultProps = {
  text:  [
    {
        "_key": "f9d17a10baa5",
        "_type": "block",
        "children": [
            {
                "_key": "8f25454437bd",
                "_type": "span",
                "marks": [],
                "text": "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Minima saepe dignissimos in nemo inventore doloribus! Commodi eligendi aperiam sapiente unde in aliquam, amet quas! Beatae saepe mollitia eius quibusdam esse."
            }
        ],
        "markDefs": [],
        "style": "normal"
    },
    {
        "_key": "8b2a5b979d61",
        "_type": "block",
        "children": [
            {
                "_key": "b8c2c46be150",
                "_type": "span",
                "marks": [],
                "text": "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Minima saepe dignissimos in nemo inventore doloribus! Commodi eligendi aperiam sapiente unde in aliquam, amet quas! Beatae saepe mollitia eius quibusdam esse."
            }
        ],
        "markDefs": [],
        "style": "normal"
    }
  ],
}

export default TextModule

