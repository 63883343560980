import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { Link } from "gatsby"
import { breakpoints } from '~styles/global'
import Image from '~components/Image'
import Button from '~components/blocks/Button'
import { SlideDown } from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'
import { useState, useEffect } from 'react'
import RichText from '~components/RichText'

const ImageAndTextModule = ({ className, content, borderBottom = true }) => {

	const {mobile, tablet} = breakpoints
	const reverse = content?.layout === 'imageRight'

	return (
		<div
			className={className}
			css={css`
				display: grid;
				grid-template-columns: repeat(12, 1fr);
				grid-column-gap: 20px;
				padding: 0 40px;
				${mobile}{
					grid-column-gap: 10px;
					padding: 0 25px;
				}
			`}
		>
				<div
					css={css`
						overflow: hidden;
						grid-column: ${reverse ? `7/13` : `1/7`};
						/* height: 100%; */
						align-self: start;
						position: relative;
						${mobile}{
							grid-column: span 12;
							height: auto;
							margin-bottom: 40px;
							grid-row: 1;
						}
				`}>
					<div css={css`
						position: absolute;
						top: 0;
						left: 0;
						right: 0;
						bottom: 0;
						z-index: 1;
						display: grid;
						align-items: end;
						padding: 25px;
						pointer-events: none;
						${mobile}{
							padding: 10px 10px;
						}
					`}>
						<h6>{content?.title}</h6>
					</div>
					<div css={css`
					`}>
						<Image
							asset={content?.image?.asset?.id}
							css={css`
								height: 100%;
								.gatsby-image-wrapper {
									height: 100%;
								}
							`}
						/>
					</div>
				</div>
				<div
				css={css`
					overflow: hidden;
					grid-column: ${reverse ? `2/6` : `8 / 12`};
					grid-row: 1;
					position: relative;
					display: flex;
					align-items: ${content?.verticalAlign === 'top' ? 'flex-start' : 'center'};
					${mobile}{
						margin-top: 0;
						grid-column: span 12;
						grid-row: 2;
					}
				`}>
						<div>
							<h4 css={css`
								margin-bottom: 28px;
								${mobile}{
									margin-bottom: 20px;
								}
							`}>
								{content?.title}
							</h4>
							<h4 css={css`
								margin-bottom: 20px;
								${mobile}{
									margin-bottom: 15px;
								}
							`}>
								<div>{content?.subtitle}</div>
							</h4>
							<div css={css`
								padding-bottom: 34px;
								${mobile}{
									padding-bottom: 0px;
								}
							`}>
								<RichText content={content?.text}/>
							</div>
							{/* <Button 
								to={link}
								css={css`
									padding-top: 10px;
									${mobile}{
										margin-bottom: ${borderBottom ? `0px` : `106px;`};
									}
								`}
							>See Collection</Button> */}
						</div>
						<div css={css`
							display: none;
							${mobile}{
								display: block;
								border-bottom: ${borderBottom ? `none` : `1px solid var(--grey)`};
							}
						`}>
						</div>
				</div>
		</div>
	)
}

const Wrap = styled.div`
	
`

ImageAndTextModule.propTypes = {
	className: PropTypes.string
}

export default ImageAndTextModule