import React, { useState } from 'react'
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { breakpoints } from '~styles/global'
import Button from '~components/blocks/Button'
import Image from '~components/Image'
import { Link } from "gatsby"
import styled from '@emotion/styled'
import resolveLink from '~utils/resolveLink'

const ContentTile = ({ className, content }) => {

	const {mobile} = breakpoints
  const [hovered, setHovered] = useState(false)

	return (
    <Link
      className={className}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      to={resolveLink(content?.link?.document?._type, content?.link?.document?.slug?.current, (content?.link?.document?.parent?.slug?.current || content?.link?.document?.defaultParentCategory?.slug?.current))}
			css={css`width: 100%;`}
    >
      <div css={css`
        margin-bottom: 28px;
        @media (max-width: 750px) {
          margin-bottom: 17px;
        }
      `}>
        <div css={css`
          transform: ${hovered ? 'translateY(-10px)' : 'translateY(0px)'};
          transition: 0.3s ease-in-out transform;
          @media (max-width: 750px) {
            transform: none;
          }
        `}>
          <Image
            asset={content?.image?.asset?.id}
          />
        </div>
      </div>
      <h4 css={css`
        margin-bottom: 34px;
      `}>
        {content?.title}
      </h4>
      <div css={css`
				mask-border: 0px;
				padding-bottom: 58px;
				@media (max-width: 750px) {
					padding-bottom: 0px;
				}
      `}>
        <p>
          {content?.text}
        </p>
        <Button css={css`display: inline-grid;`} noLink>{content?.buttonText}</Button>
      </div>
    </Link>
	)
}

const Wrap = styled.div`
	
`

ContentTile.propTypes = {
	className: PropTypes.string
}

export default ContentTile