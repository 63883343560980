import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import Section from '~components/Section'
import Image from '~components/Image'
import RichText from '~components/RichText'

const index = ({ content }) => {

	const mobile = '@media (max-width: 768px)'

	return (
		<Section 
			css={css`
				margin-bottom: 194px;
				>div{
					grid-template-rows: max-content max-content 1fr;
					height: calc(100vh - 188px);
					${mobile}{
						height: auto;
					}
				}
				${mobile}{
					margin-bottom: 106px;
				}
		`}>
			<div css={css`
				grid-column: span 12;
				margin-bottom: 25px;
				${mobile}{
					margin-bottom: 22px;
				}
			`}>
				<h4>{content?.title}</h4>
			</div>
			<div css={css`
				grid-column: 1/5;
				${mobile}{
					grid-column: span 12;
					margin-bottom: 84px;
				}
			`}>
				{content?.subtitle}
			</div>
			<div css={css`
				grid-column: 5/8;
				margin-bottom: 42px;
				${mobile}{
					grid-column: span 12;
				}
			`}>
				<RichText content={content?.text}/>
			</div>
			<div css={css`
				grid-column: 5/11;
				grid-row: 3;
				align-self: flex-end;
				${mobile}{
					grid-column: span 12;
					grid-row: 4;
				}
			`}>
				<Image 
					asset={content?.image?.asset?.id} 
				/>
		</div>
	</Section>
	)
}

const Wrap = styled.div`
	
`

index.propTypes = {
	className: PropTypes.string
}

export default index